
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppPlayerDashboardRootUserInformationFormUpdate} from "@/models/app/player/dashboard/root/user-information/form/update";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElDatePicker,
  ElButton,
} from "element-plus";
import {Edit} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  props: [
    "userInformation",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElDatePicker,
    ElButton,
    Edit,
  },
})
export default class AppPlayerDashboardRootUserInformationFormUpdateSponsorIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  userInformation: AppPlayerDashboardRootUserInformationFormUpdate = new AppPlayerDashboardRootUserInformationFormUpdate();

  formData: AppPlayerDashboardRootUserInformationFormUpdate = new AppPlayerDashboardRootUserInformationFormUpdate();

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/user/information/update",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getUserInformation');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    this.formData = this.userInformation;
  }

  resetFormData(): void {
    this.formData = new AppPlayerDashboardRootUserInformationFormUpdate();
  }

  updated(): void {
    this.setFormData();
  }
}
