
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import PlayerNavigationVue from "@/components/player/navigation.vue";
import AppPlayerDashboardRootMatchesIndexVue from "@/views/app/player/dashboard/root/matches/index.vue";
import AppPlayerDashboardRootTrainingsIndexVue from "@/views/app/player/dashboard/root/trainings/index.vue";
import AppPlayerDashboardRootNotificationsIndexVue from "@/views/app/player/dashboard/root/notifications/index.vue";
import AppPlayerDashboardRootPostsIndexVue from "@/views/app/player/dashboard/root/posts/index.vue";
import AppPlayerDashboardRootUserInformationIndexVue from "@/views/app/player/dashboard/root/user-information/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    PlayerNavigationVue,
    AppPlayerDashboardRootMatchesIndexVue,
    AppPlayerDashboardRootTrainingsIndexVue,
    AppPlayerDashboardRootNotificationsIndexVue,
    AppPlayerDashboardRootPostsIndexVue,
    AppPlayerDashboardRootUserInformationIndexVue,
  },
})
export default class AppPlayerDashboardRootIndexVue extends Vue {}
