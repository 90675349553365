
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Components
import {
  DataBoard,
  Football,
  DataLine,
  FirstAidKit,
  FolderOpened,
  Notebook,
  BellFilled,
} from "@element-plus/icons-vue";
import AuthenticationLogoutIndexVue from "@/views/authentication/logout/index.vue";
import SwitchOrganizationIndexVue from "@/components/switch-organization.vue";

@Options({
  components: {
    DataBoard,
    Football,
    DataLine,
    FirstAidKit,
    FolderOpened,
    Notebook,
    BellFilled,
    AuthenticationLogoutIndexVue,
    SwitchOrganizationIndexVue,
  },
})
export default class PlayerNavigationVue extends Vue {
  isNavigationListVisible = false;
  exactRoute = "";

  setExactRoute(): void {
    switch (this.$route.name) {
      case "AppPlayerDashboardRootIndexVue":
        this.exactRoute = "dashboard";
        break;
      case "AppPlayerMatchesRootIndexVue":
      case "AppPlayerMatchesCategoriesIndexVue":
        this.exactRoute = "matches";
        break;
      case "AppPlayerTrainingsRootIndexVue":
      case "AppPlayerTrainingsCategoriesIndexVue":
        this.exactRoute = "trainings";
        break;
      case "AppPlayerHealthRecordsRootIndexVue":
        this.exactRoute = "health-records";
        break;
      case "AppPlayerResourcesRootIndexVue":
      case "AppPlayerResourcesSupplementsIndexVue":
      case "AppPlayerResourcesDietsIndexVue":
      case "AppPlayerResourcesExercisesIndexVue":
        this.exactRoute = "resources";
        break;
      case "AppPlayerNotesRootIndexVue":
        this.exactRoute = "notes";
        break;
      case "AppPlayerTipsRootIndexVue":
        this.exactRoute = "tips";
        break;
    }
  }

  get hasUserOrganizationMemberships(): boolean {
    return store.getters.getOrganizationMembership.length > 1;
  }

  created(): void {
    this.setExactRoute();
  }
}
